@import '../../../../styles/variables';


.conditionsContainer {
  z-index: 1;
  margin: 0 auto;
  width: 100%;
  max-width: calc((100vh - 300px)* 16 / 9);
  background-color: rgba(white, 0.9);
  padding: 8px;
  border-radius: 0 0 5px 5px;
  min-height: 50px;
}

.conditionsColorBar {
  width: 5px;
  border-radius: 2px;
}

.conditionsBlock {
  height: auto;
  width: 6px;
  border-radius: 99px;
  margin: auto 10px auto 0;
}

.conditionsBlockSmall {
  margin: 0 10px 0 0;
}

.isTooltip {
  height: auto;
}

.waveHeight {
  margin: 0;
  margin-right: 5px;
}


.errorBox {
  background-color: rgba(#f5424b, 0.2);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.errorBoxText {
  color: $error;
}
