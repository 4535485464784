@import '../../styles/variables';
@import '../../styles/mixins';

.buttons {
  align-items: center;
  display: inline-flex;
  justify-content: right;
  text-align: right;

  .Mui-disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.arrowButton {
  padding-right: 0;

  circle {
      stroke: var(--sl-button-color);
  }

  path {
      fill: var(--sl-button-color);
  }
}

.arrowButton:disabled {
  opacity: 0.3;
}

.buttons {
  margin-left: 20px;
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.scrollArrows {
  @media (max-width: $tablet) {
    display: none;
  }
}

.callToActionButton {
  display: inline-flex;
}

.callToActionButtonMobile {
  text-align: center;
}
