@import '../../styles/variables.scss';

.card {
  backdrop-filter: blur(8px);
  background: rgba(250, 250, 250, 0.08);
  margin: 0 auto 16px;
  max-width: 760px;
  padding-bottom: 16px;
  overflow: visible;

  :global {
    .MuiCardHeader-root {
      position: relative;
      padding: 0;
    }

    .MuiCardHeader-title {
      color: $background;
      font-size: 18px;
      margin-right: 8px;
    }

    .MuiCardHeader-content {
      align-items: baseline;
      display: flex;
    }

    .MuiCardHeader-subheader {
      color: $textSecondary;

      button {
        &:hover,
        &:focus-visible {
          text-decoration: none;
        }
      }
    }

    .MuiCardContent-root {
      padding: 16px 0 0;
    }

    .MuiCardActions-root {
      padding: 16px 0 0;
      position: relative;

      &::before {
        background: $textSecondary;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}
