@import '../../styles/variables';

$card-border-radius: 8px;

.card {
  background-color: $product-base;
  border-radius: $card-border-radius;
  display: block;
  height: 100%;
  overflow: hidden;
  padding: 0;
  position: relative;
  scroll-snap-align: center;
  width: 100%;

  // fix for border radius with child elements in safari
  // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
  transform: translateZ(0);

  /* Prevent background color leak outs */
  -webkit-background-clip: padding-box;
  -moz-background-clip:    padding;
  background-clip:         padding-box;

  @media (min-width: $tablet) {
    scroll-snap-align: none;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &:focus-visible,
  &:active {
    box-shadow: 0px 4px 8px rgb(152 162 175 / 25%);
  }
}

.camPlayerPlaceholder {
  aspect-ratio: 16/9;
  background-color: $background;
}

.camPlayerLabel {
  display: flex;
  padding: 15px;

  @media (min-width: $tabletLg) {
    padding: 20px;
  }

  .spotName {
    font-weight: 600;
    padding-bottom: 2px;
    margin-bottom: 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (min-width: $tabletLg) {
      padding-bottom: 4px;
    }
  }
}

.sideThumbnailLabel {
  padding: 10px;
}

.sideThumbnail {
  border-radius: 8px;
  height: 80px;
  margin-right: 10px;
  min-width: 142px;
  overflow: hidden;
  position: relative;
  width: 142px;
}

@media (max-width: $tabletLg) {
  .sideThumbnailLabel {
    :global {
      h6 {
        height: unset;
      }

      h4 {
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      figure {
        background-color: transparent;
        position: unset;
      }

      .current-wave-height-and-rating {
        align-items: center;
      }
    }
  }
}

.iconsWrapper {
  display: flex;
  justify-content: flex-end;
}
