@import '../../styles/variables';

@keyframes fadeInItems {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.scrollContainer {
  -ms-overflow-style: none; /* IE and Edge */
  -webkit-scroll-behavior: smooth;
  -webkit-scroll-snap-type: x mandatory;
  display: flex;
  list-style: none;
  margin: 0 0 0 -30px;
  overflow: auto;
  padding: 0;
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scrollbar-width: none; /* Firefox */
  width: calc(100% + 60px);

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: $tablet) {
    margin-left: -40px;
    width: calc(100% + 80px);
  }

  @media (min-width: $desktop) {
    margin-left: -20px;
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 1.5%, rgba(0, 0, 0, 1) 98.5%, rgba(0, 0, 0, 0) 100%);
    width: calc(100% + 40px);
  }
}

.item {
  animation: fadeInItems 200ms ease-in-out;
  flex-shrink: 0;
  padding: 0 20px 5px;
  width: calc(100% - 60px);

  @media (min-width: $tablet) {
    width: calc((100% / 2) - 25px);
  }

  @media (min-width: $desktop) {
    width: calc((100% / 3) - 20px);
  }

  @media (min-width: $desktopLg) {
    width: calc((100% / 4) - 20px);
  }
}

.link {
  text-decoration: none;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
}

.viewAllButton {
  @media (min-width: $tablet) {
    margin-right: 10px;
  }
}

.arrow {
  display: none;

  @media (min-width: $tablet) {
    display: inline-flex;
    height: 44px;
    width: 44px;
    margin-top: -6px;

    &:nth-child(2) {
      padding-right: 2px;
    }

    &:nth-child(3) {
      padding-left: 2px;
    }

    svg {
      circle {
        stroke: $grayLight;
      }

      path {
        fill: $textPrimary;
      }
    }

    &:hover,
    &:focus-visible {
      svg {
        circle {
          stroke: $textPrimary;
        }
      }
    }

    &:disabled {
      cursor: not-allowed;

      svg {
        circle {
          stroke: $grayLight;
        }

        path {
          fill: $grayLight;
        }
      }
    }
  }
}
